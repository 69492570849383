import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';
import { IconButton, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from '@mui/material';
import { useSubscriptionContext } from '../subscription/SubscriptionStateContext';
import { UserAccessState } from '../types';

export default function DemoToggleButton(
    { isDemoMode, handleDemoToggle }: { isDemoMode: boolean, handleDemoToggle: () => void }
) {
    const { userSubscriptionState } = useSubscriptionContext();
    const allowedToToggle = [
        UserAccessState.SIGNUP_COMPLETE,
        UserAccessState.GLIMPSE_INTERNAL_READ,
        UserAccessState.GLIMPSE_INTERNAL_WRITE,
        UserAccessState.CUSTOMER_OPERATOR
    ].includes(userSubscriptionState)

    const openLinkInNewTab = () => {
        const demoDataLink = "https://figshare.com/s/d81465707bb7313fe85d"
        window.open(demoDataLink, '_blank');
    };
    const demoToggle = () => isDemoMode.toString() === "true" ? "demo" : "private"
    return (
        <>
            {
                isDemoMode &&
                <Tooltip title={<Typography variant="body1">Download the raw demo dataset</Typography>}>
                    <IconButton onClick={openLinkInNewTab} sx={{ mr: 1 }}>
                        <DownloadForOfflineOutlinedIcon />
                    </IconButton >
                </Tooltip>
            }
            <Tooltip title={<Typography variant="body1">Demo Mode includes commercially off-the-shelf battery scans only.</Typography>}>
                <ToggleButtonGroup
                    color="primary"
                    exclusive
                    value={demoToggle()}
                    aria-label="Platform"
                    disabled={!allowedToToggle}
                    onChange={(event, newDemoToggle) => { if (newDemoToggle) { handleDemoToggle() } }}
                    sx={{ mr: 2 }}
                    size='small'
                >
                    <ToggleButton value="demo" sx={{ width: 200 }} size='small'>
                        Demo Mode
                    </ToggleButton>
                    <ToggleButton value="private" sx={{ width: 200 }} size='small'>
                        Private Mode
                    </ToggleButton>
                </ToggleButtonGroup>
            </Tooltip>
        </>
    )
}

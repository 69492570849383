import React, { createContext, useContext, useEffect, useState } from 'react';
import { useAuthTokenAndAccessApi } from '../auth/authHooks';
import { UserAccessState } from '../types';
import { ApiEndpoints } from '../utils/apiUtils';

interface SubscriptionStateContextType {
    userSubscriptionState: UserAccessState;
    featureAccessState: {
        internalToolsPage: boolean;
        apiDocs: boolean;
        operatorPage: boolean;
        serialNumberRenamingTool: boolean;
    }
}

const SubscriptionStateContext = createContext<SubscriptionStateContextType | undefined>(undefined);

export const SubscriptionStateProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { fetchData, isLoading, isAuthenticated, isVerified } = useAuthTokenAndAccessApi();

    // TODO: this concept is overloaded. it includes loading, error, and what should we show them state.
    const [userSubscriptionState, setUserSubsriptionState] = useState<UserAccessState>(UserAccessState.LOADING)

    const [featureAccessState, setFeatureAccessState] = useState({
        internalToolsPage: false,
        apiDocs: false,
        operatorPage: false,
        serialNumberRenamingTool: false,
    });

    useEffect(() => {
        if ((isAuthenticated && !isLoading)) { fetchUserStatus() }
    }, [isAuthenticated, isLoading])

    const fetchUserStatus = async () => {
        setUserSubsriptionState(UserAccessState.LOADING);
        const isVerifiedBool = await isVerified();
        if (!isVerifiedBool) {
            setUserSubsriptionState(UserAccessState.EMAIL_UNVERIFIED)
            return
        }
        try {
            const userStatus = await fetchData(ApiEndpoints.CHECK_USER);
            if (!userStatus) {
                setUserSubsriptionState(UserAccessState.SIGNUP_INCOMPLETE);
                return;
            }
            const status = userStatus.data.user_access_status as UserAccessState
            setUserSubsriptionState(status);

            if (status === UserAccessState.CUSTOMER_OPERATOR) {
                setFeatureAccessState({
                    internalToolsPage: false,
                    operatorPage: true,
                    serialNumberRenamingTool: false,
                    apiDocs: true
                });
            }

            // TODO: This is temp to release this feature to just 1027 - drop when we release to other customers:
            if (process.env.REACT_APP_USE_COGNITO === 'TRUE') {
                setFeatureAccessState({ ...featureAccessState, serialNumberRenamingTool: true });
            }

            // feature access control:
            if ([UserAccessState.GLIMPSE_INTERNAL_WRITE, UserAccessState.GLIMPSE_INTERNAL_READ].includes(status)) {
                setFeatureAccessState({
                    internalToolsPage: true,
                    operatorPage: true,
                    serialNumberRenamingTool: true,
                    apiDocs: true
                });
            }
        } catch (err) {
            console.error("Error fetching user status: ", err);
            setUserSubsriptionState(UserAccessState.LOADING);
        }
    };


    return (
        <SubscriptionStateContext.Provider value={{ userSubscriptionState, featureAccessState }}>
            {children}
        </SubscriptionStateContext.Provider>
    );
};

export const useSubscriptionContext = () => {
    const context = useContext(SubscriptionStateContext);
    if (context === undefined) {
        throw new Error('useApiState must be used within an SubscriptionStateProvider');
    }
    return context;
};

import useUrlState from "@ahooksjs/use-url-state";
import { useEffect, useState } from "react";
import { useAuthTokenAndAccessApi } from "../auth/authHooks";
import { useSubscriptionContext } from "../subscription/SubscriptionStateContext";
import { PageOptions, ScanList, UserAccessState } from "../types";
import { ApiEndpoints } from "../utils/apiUtils";
import AppMainLayoutComponent from "./AppMainLayoutComponent";


export default function AppInitialDataLoading() {
    const [urlState, setUrlState] = useUrlState<{ page: PageOptions, public?: boolean }>({ page: PageOptions.SCANS })
    const { fetchData, isLoading, isAuthenticated } = useAuthTokenAndAccessApi();
    const [scanList, setScanList] = useState<ScanList[]>([{} as ScanList])
    const [isDemoMode, setIsDemoMode] = useState<boolean>(true)

    const { userSubscriptionState } = useSubscriptionContext();

    useEffect(() => {
        if ((userSubscriptionState !== UserAccessState.LOADING) || urlState.page === PageOptions.FAQ) {
            stateCoordinatorAndInitialDataLoad()
        }
    }, [userSubscriptionState])

    // TODO:could be refactored now that we centralized the access state in the subscription context:
    const stateCoordinatorAndInitialDataLoad = async () => {
        const status = userSubscriptionState as UserAccessState
        switch (status) {
            case UserAccessState.SIGNUP_COMPLETE:
            case UserAccessState.GLIMPSE_INTERNAL_READ:
            case UserAccessState.GLIMPSE_INTERNAL_WRITE:
            case UserAccessState.CUSTOMER_OPERATOR:
                if (urlState.public === "true") {
                    setIsDemoMode(true);
                    setUrlState({ page: urlState.page, public: true })
                    await initialDataLoader(true);
                }
                else if (urlState.public === "false") {
                    setIsDemoMode(false);
                    setUrlState({ page: urlState.page, public: false })
                    await initialDataLoader(false);
                }
                // if no state provided in the link, assume private:
                else {
                    setIsDemoMode(false);
                    setUrlState({ page: urlState.page, public: false })
                    await initialDataLoader(false);
                }
                break;
            case UserAccessState.SIGNUP_INCOMPLETE:
            default:
                setIsDemoMode(true)
                setUrlState({ page: urlState.page, public: true })
                await initialDataLoader(true)
                break;
        }
    }

    const handleDemoToggle = () => {
        initialDataLoader(!isDemoMode)
        setIsDemoMode(!isDemoMode);
        setUrlState({ page: urlState.page, public: !isDemoMode })
    }

    async function initialDataLoader(isDemoMode: boolean) {
        await loadScanListFromApi(isDemoMode)
    }


    const loadScanListFromApi = async (demoMode: boolean) => {
        let url = ApiEndpoints.SCAN_LIST
        if (demoMode) url = ApiEndpoints.DEMO_SCAN_LIST
        const scanResponse = await fetchData(url);
        if (scanResponse.data.length === 0) setScanList([] as ScanList[])
        if (scanResponse.data.length > 0) {
            setScanList((scanResponse.data as ScanList[]).sort((a, b) => b.scan_id - a.scan_id));
        }
    }

    return <AppMainLayoutComponent
        selectedPage={urlState.page}
        isLoginLoading={isLoading}
        isUserAuthenticated={isAuthenticated}
        scanList={scanList}
        isDemoMode={isDemoMode}
        handleDemoToggle={handleDemoToggle}
    />
}

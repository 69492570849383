import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useSubscriptionContext } from '../subscription/SubscriptionStateContext';
import { PageOptions, UserAccessState } from '../types';
import ContactSales from './ContactSales';
import DemoToggleButton from './DemoToggle';
import { ReactComponent as Logo } from "./GlimpseLogo.svg";
import HelpButton from './HelpButton';
import LogoutButton from './LogoutButton';
import UserButtonHeader from './UserButtonHeader';

export default function TopNavigationBar(
    {
        isDemoMode,
        handleDemoToggle,
        selectedPage,
        topBarHeight
    }: {
        isDemoMode: boolean,
        handleDemoToggle: () => void,
        selectedPage: PageOptions,
        topBarHeight: number
    }
) {
    const { userSubscriptionState } = useSubscriptionContext();

    const logoStyle = {
        display: 'flex',
        height: '80px',
        marginTop: '-40px',
    }
    return (
        <AppBar position="fixed" sx={{ zIndex: 2000, height: topBarHeight }}>
            <Toolbar>
                <Logo style={logoStyle} />
                <Typography variant='h6' align='left' sx={{ pl: 3, pt: 1, flexGrow: 1, color: 'red' }} >
                </Typography>

                <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
                </Typography>
                {userSubscriptionState !== UserAccessState.LOADING && selectedPage !== PageOptions.TERMS &&
                    <DemoToggleButton
                        isDemoMode={isDemoMode}
                        handleDemoToggle={handleDemoToggle}
                    />
                }
                <UserButtonHeader />
                <ContactSales />
                <HelpButton />
                <LogoutButton />
            </Toolbar>
        </AppBar >
    )
}

import useUrlState from "@ahooksjs/use-url-state";
import Battery0BarOutlinedIcon from '@mui/icons-material/Battery0BarOutlined';
import BatterySaverOutlinedIcon from '@mui/icons-material/BatterySaverOutlined';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import HelpCenterOutlinedIcon from '@mui/icons-material/HelpCenterOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import InsightsOutlinedIcon from '@mui/icons-material/InsightsOutlined';
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';
import MonitorOutlinedIcon from '@mui/icons-material/MonitorOutlined';
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import { useSubscriptionContext } from "../subscription/SubscriptionStateContext";
import { PageOptions } from "../types";
import { openTermsPageNewTab } from "../utils";

export default function LeftNavigationDrawer(
    {
        drawerWidth,
        selectedPage,
        isDemoMode
    }: {
        drawerWidth: number,
        selectedPage: PageOptions,
        isDemoMode: boolean
    }
) {
    // We need all the url store state used anywhere here, so we can avoid buggy unexpected behavior
    //   and annoying component re-rendering when the url changes.
    // eslint-disable-next-line
    const [selectedPageUrl, setSelectedPageUrl] = useUrlState({
        page: PageOptions.SCANS,
        selectedScanId: undefined,
        axial: undefined,
        radial: undefined,
        xy: undefined,
        yz: undefined,
        xz: undefined
    })

    const { featureAccessState } = useSubscriptionContext();

    let navbarLinks = () => {
        let navbarLinks = [
            { text: 'List of Scans', page: PageOptions.SCANS, icon: ListOutlinedIcon },
            { text: 'Scan Viewer', page: PageOptions.SLICES, icon: GridViewOutlinedIcon },
            { text: 'Automated Inspection Review', page: PageOptions.INSPECTION_DASHBOARD, icon: InsightsOutlinedIcon },
            { text: 'FAQ', page: PageOptions.FAQ, icon: HelpCenterOutlinedIcon },
        ];
        const internal_page_read = { text: 'Glimpse Internal Page - Read', page: PageOptions.INTERNAL_READ, icon: Battery0BarOutlinedIcon }
        const internal_page_write = { text: 'Glimpse Internal Page - Write', page: PageOptions.INTERNAL_WRITE, icon: BatterySaverOutlinedIcon }
        const operator_page = { text: 'Operator Page', page: PageOptions.OPERATOR, icon: MonitorOutlinedIcon }

        if (featureAccessState.operatorPage) { navbarLinks.push(operator_page) }

        if (featureAccessState.internalToolsPage) { navbarLinks.push(internal_page_read, internal_page_write) }

        return navbarLinks
    }
    const handlePageSelection = (page: PageOptions) => {
        // undefined clears the state from the url
        setSelectedPageUrl({
            page: page,
            // makes sure we have a clean selection on the slice viewer:
            selectedScanId: page === PageOptions.SLICES ? "" : undefined,
            axial: undefined,
            radial: undefined,
            xy: undefined,
            yz: undefined,
            xz: undefined
        })
    }
    return (
        <Drawer
            sx={{
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth + 4,
                    boxSizing: 'border-box',
                    top: '58px',
                    height: 'auto',
                    bottom: 0,
                },
            }}
            anchor="left"
            variant="permanent"
        >
            <Divider />
            <List>
                {navbarLinks().map(({ text, page, icon: Icon }) => (
                    <Tooltip title={<Typography variant="body1">{text}</Typography>} placement="right" arrow key={page}>
                        <ListItem key={page} disablePadding  >
                            <ListItemButton onClick={() => handlePageSelection(page)} selected={page === selectedPage}>
                                <Icon />
                            </ListItemButton>
                        </ListItem>
                    </Tooltip>
                ))}
            </List>
            {isDemoMode && <>
                <Box sx={{ flexGrow: 1 }}>
                </Box>
                <Box sx={{ p: 1 }}>
                    <Tooltip title={<Typography variant="body1">Click to view the demo's terms of use</Typography>} placement="right" arrow>
                        <IconButton onClick={() => {
                            window.open(openTermsPageNewTab(), '_blank');
                        }}>
                            <InfoOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            </>
            }
        </Drawer >
    );
}

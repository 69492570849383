import { IconButton, Tooltip, Typography } from "@mui/material";
import { brushX, create, extent, group, scaleLinear, scaleTime } from "d3";
import { useEffect, useRef, useState } from "react";
import {
    ChartSeriesOptions,
    InspectionChartData,
    PlotDataWithX, ScanPreviewState,
    transformData, XAxisOptions
} from "../../utils/inspection/dashboard";

import './plotStyles.css';

import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import { createOneFacet } from "../../utils/inspection/dashboardDefaultPlot";

export default function FacetPlot(
    {
        data,
        plotDims,
        xAxisOption,
        horizontalLines,
        previewState,
        setPreviewState,
        checkboxes,
    }: {
        data: InspectionChartData[];
        plotDims: { width: number; height: number };
        xAxisOption: XAxisOptions;
        horizontalLines: number[];
        previewState: ScanPreviewState;
        setPreviewState: (state: ScanPreviewState) => void;
        checkboxes: { mean: boolean; median: boolean; min: boolean; max: boolean };
    }) {
    const [isShiftDown, setIsShiftDown] = useState(false);
    const [xValueFilter, setXValueFilter] = useState<[number | Date, number | Date] | null>(null);

    const containerRef = useRef<HTMLDivElement>(null);

    const tidyPlotData = transformData(data, xAxisOption)
        // Note: Mean is always present, but null. This makes the x axis appear consistent across facets:
        .map((e) => !checkboxes.mean && e.series === ChartSeriesOptions.MEAN ? { ...e, value: null } : e)
        .filter((e) => e.series !== ChartSeriesOptions.MEDIAN || checkboxes.median)
        .filter((e) => e.series !== ChartSeriesOptions.MIN || checkboxes.min)
        .filter((e) => e.series !== ChartSeriesOptions.MAX || checkboxes.max)

    const marginBottomBetweenPlots = 20
    const marginTop = 20
    const marginRight = 20
    const yInset = 10
    const tickSize = 2

    const isDate = xAxisOption === XAxisOptions.DATE;

    const plotData = isDate ?
        tidyPlotData.filter(d => xValueFilter ? d.date >= xValueFilter[0] && d.date <= xValueFilter[1] : true) :
        tidyPlotData.filter(d => xValueFilter ?
            typeof d.x === "number"
            && typeof xValueFilter[0] === "number"
            && typeof xValueFilter[1] === "number"
            && d.x >= xValueFilter[0]
            && d.x <= xValueFilter[1]
            : true);

    /**
     * Create a d3 brush element for use in zooming. https://d3js.org/d3-brush
     * This takes in relevant plot html div elements, and produces an SVG element with a brush.
     * It scales the axis and sets the zoom extents.
     */
    const createZoomWindow = (
        plotDiv: HTMLDivElement,
        numberOfCharts: number,
        xDomain: [number, number],
        marginLeft: number,
        isDate: boolean,
        xInset: number
    ) => {
        const svgHeight = plotDiv.getBoundingClientRect().height;

        // some d3 fuckery :(
        const svg = create("svg")
            .attr("width", plotDims.width)
            .attr("height", svgHeight)
            .style("position", "absolute")
            .style("top", "0")  // <- relative not absolute here
            .style("left", "0")
            .style("pointer-events", "none");

        const svgNode = svg.node();
        if (svgNode) { plotDiv.appendChild(svgNode); }

        const brushWidth = plotDims.width - marginRight - xInset;

        let brushHeight = plotDims.height + (yInset * 2) - tickSize;
        if (numberOfCharts > 1) brushHeight =
            (plotDims.height * numberOfCharts) +
            (yInset * numberOfCharts) +
            ((marginBottomBetweenPlots) * (numberOfCharts)) +
            numberOfCharts

        const xScale = isDate ?
            scaleTime().domain([new Date(xDomain[0]), new Date(xDomain[1])])
                .range([marginLeft, brushWidth]) : scaleLinear().domain(xDomain)
                    .range([marginLeft, brushWidth]); // FYI range is pixel space

        const brush = brushX<SVGGElement>()
            .extent([
                [marginLeft, marginTop],
                [brushWidth, brushHeight]
            ])
            .on("end", (event) => {
                if (!event.selection) return;
                const [x0, x1] = event.selection;
                const inverted = [xScale.invert(x0), xScale.invert(x1)]
                const diff = inverted[1] as number - (inverted[0] as number)
                // guards against zooming in too far.
                if (isDate && diff < 1000 * 60 * 60) return;
                if (!isDate && diff < 3) return;
                setXValueFilter([xScale.invert(x0), xScale.invert(x1)]);
            });

        svg.append("g")
            .attr("class", "brush")
            .call(brush as any)
            .style("pointer-events", "all");
    }


    const generatePlots = (
        xDomain: [number, number],
        groupedData: d3.InternMap<string, PlotDataWithX[]>,
        sortedKeys: string[],
        marginLeft: number,
        marginBottomLastPlot: number,
        xInset: number
    ) => {
        const plotElements: HTMLDivElement[] = [];
        sortedKeys.forEach((category, index) => {
            const facetData = groupedData.get(category);
            if (!facetData) return;
            const isLastPlot = index === sortedKeys.length - 1;
            const calculatedMargins = {
                top: marginTop,
                left: marginLeft,
                bottom: isLastPlot ? marginBottomLastPlot : marginBottomBetweenPlots,
                right: marginRight
            }
            const plot = createOneFacet(
                facetData,
                category,
                plotDims.width,
                // total height here:
                plotDims.height + calculatedMargins.bottom + calculatedMargins.top,
                xAxisOption,
                horizontalLines,
                previewState,
                xDomain,
                isLastPlot, // showXAxis = isLastPlot
                calculatedMargins,
                xInset,
                yInset,
                tickSize
            );

            const plotDiv = document.createElement("div");
            plotDiv.appendChild(plot);
            plotElements.push(plotDiv);
            if (xAxisOption !== XAxisOptions.BATCH) {
                plot.addEventListener("mousedown", () => {
                    const { scanId, sliceId, metric, series, value } = plot.value ?? {};
                    if (scanId === undefined) return;
                    setPreviewState({ scanId, sliceId, metric, series, value });
                });
            }
        });

        return plotElements;
    };


    useEffect(() => {
        if (!containerRef.current) return;
        const keydownListener = (event: KeyboardEvent) => { if (event.key === "Shift") { setIsShiftDown(true) } };
        const keyupListener = (event: KeyboardEvent) => { if (event.key === "Shift") { setIsShiftDown(false) } };

        let marginLeft = 65
        let marginBottomLastPlot = 70

        const xInset = 10

        if (!isDate) {
            const longestSnString = Math.max(...plotData.map(d => d.sn.length));
            marginBottomLastPlot = 30 + longestSnString * 3;
            marginLeft = 65 + longestSnString * 2;
        }

        containerRef.current.innerHTML = "";
        const plotDiv = containerRef.current


        const xDomain = extent(plotData, d => (isDate ? d.date.getTime() : d.x)) as [number, number];
        const groupedData = group(plotData, d => d.metric);
        const sortedKeys = Array.from(groupedData.keys()).sort((a, b) => a.localeCompare(b));
        const plotElements = generatePlots(xDomain, groupedData, sortedKeys, marginLeft, marginBottomLastPlot, xInset);

        plotElements.forEach(element => plotDiv?.appendChild(element));

        if (isShiftDown) createZoomWindow(plotDiv, sortedKeys.length, xDomain, marginLeft, isDate, xInset);

        document.addEventListener("keydown", keydownListener);
        document.addEventListener("keyup", keyupListener);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        return () => {
            document.removeEventListener("keydown", keydownListener);
            document.removeEventListener("keyup", keyupListener
            );
        };
    }, [plotData, xAxisOption]);

    useEffect(() => { setXValueFilter(null) }, [xAxisOption]);

    const tooltipText = xValueFilter ? "Click here to reset zoom." : "Hold shift, click, and drag to zoom in on the x-axis."

    return <>
        <div style={{ position: "relative", width: "100%" }}>
            <Tooltip title={<Typography>{tooltipText}</Typography>} >
                {
                    xValueFilter ?
                        <IconButton
                            onClick={() => setXValueFilter(null)}
                            sx={{ position: "absolute", top: -10, left: -2, zIndex: 1000 }}>
                            <ZoomOutMapIcon />
                        </IconButton> : <ZoomInIcon sx={{ position: "absolute", top: -2, left: 6, zIndex: 1000 }} />
                }
            </Tooltip>
            {
                plotData.length < 1 ?
                    <Typography sx={{ m: 2, ml: 4 }}>No data, check data filters and reset the zoom.</Typography> :
                    <div ref={containerRef} style={{ position: "relative", width: "100%", height: "100%", top: 10 }} />
            }
        </div>
    </>

};

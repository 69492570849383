import { Box, Grid, SelectChangeEvent, ToggleButton, ToggleButtonGroup } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { useSubscriptionContext } from '../../subscription/SubscriptionStateContext';
import { PageOptions, UserAccessState } from '../../types';
import AddItemForm from './AddItemForm';
import GlimpseboxRequestSelector from './GlimpseboxSelection';
import MoveRequestForm from './MoveRequestForm';
import TableLayoutComponent from './TableLayoutComponent';
import { InternalPageMenuOptions } from './internalTypes';



export default function InternalPage({ selectedPage }:
  { selectedPage: PageOptions; }
) {
  const [selectedTable, setSelectedTable] = useState(InternalPageMenuOptions.REQUESTS);
  const { userSubscriptionState } = useSubscriptionContext();

  const menuOptionsArr = Object.values(InternalPageMenuOptions)
  const handleChange = (event: SelectChangeEvent) => {
    const selection = event.target.value as InternalPageMenuOptions
    setSelectedTable(selection);
  };

  const layoutFunction = (
    selectedTable: InternalPageMenuOptions,
    selectedPage: PageOptions,
    userSubscriptionState: UserAccessState
  ) => {
    if (selectedTable === InternalPageMenuOptions.GLIMPSEBOXES) {
      return <GlimpseboxRequestSelector isWrite={selectedPage === PageOptions.INTERNAL_WRITE} />
    }
    if (selectedPage === PageOptions.INTERNAL_WRITE &&
      userSubscriptionState === UserAccessState.GLIMPSE_INTERNAL_WRITE) {
      return <>
        <AddItemForm selectedTable={selectedTable} />
        {selectedTable === InternalPageMenuOptions.REQUESTS && <MoveRequestForm />}
        <TableLayoutComponent selectedTable={selectedTable} />
      </>
    }
    return <TableLayoutComponent selectedTable={selectedTable} />
  }
  return (
    <Box sx={{ margin: "auto", height: "100%" }}>
      <Typography variant="h5" sx={{ flexGrow: 1, pt: "14pt", }}>
        Internal Management Tool:  Glimpse Only. View only for now
      </Typography>
      <Grid container sx={{ mt: 3 }} >
        <ToggleButtonGroup color="secondary" value={selectedTable} exclusive>
          {menuOptionsArr.map((option) => (
            <ToggleButton key={option} value={option}
              onClick={() => handleChange({ target: { value: option } } as any)}
            >
              {option}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </Grid>
      {layoutFunction(selectedTable, selectedPage, userSubscriptionState)}
    </Box >
  );
}

import { Box, Checkbox, Chip, FormHelperText, ListItem, ListItemText } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { SetStateAction } from 'react';



export default function MultiBatchSelect(
    {
        requestsWithData,
        selectedRequests,
        setSelectedRequests
    }: {
        requestsWithData: {
            requestName: string;
            requestId: number;
        }[],
        selectedRequests: number[]
        setSelectedRequests: React.Dispatch<SetStateAction<number[]>>
    }) {

    const handleSelectAllRequests = () => {
        if (selectedRequests.length < requestsWithData.length) {
            const allRequests = requestsWithData.map((e) => e.requestId)
            setSelectedRequests(allRequests as number[]);
        } else {
            setSelectedRequests([]);
        }
    };

    const handleChange = (requestId: number) => {
        if (selectedRequests.includes(requestId)) {
            setSelectedRequests(selectedRequests.filter((e) => e !== requestId));
        } else {
            setSelectedRequests([...selectedRequests, requestId]);
        }
    };

    return <FormControl >
        <Select
            multiple
            value={selectedRequests}
            renderValue={(selected) => (
                <Box sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: 0.5,
                    maxHeight: 90,
                    overflowY: 'auto',
                }}>
                    {selected.sort((a, b) => a - b).map((e) => (
                        <Chip variant='outlined'
                            key={e}
                            label={requestsWithData.find(f =>
                                f.requestId === e)?.requestName || ""}
                            color='primary'
                            sx={{ borderRadius: 1 }}
                        />))}
                </Box>
            )}
            size='small'
            MenuProps={{ PaperProps: { sx: { maxHeight: 600, overflowY: 'auto' } } }}
        >
            <ListItem dense  >
                <Checkbox
                    sx={{ p: 0.5, m: 0 }}
                    checked={selectedRequests.length === requestsWithData.length}
                    onChange={handleSelectAllRequests}
                />
                <ListItemText primary={"All"} />
            </ListItem>
            {requestsWithData.map((option) => (
                <ListItem
                    dense
                    key={option.requestId}
                    value={option.requestId}
                    onClick={() => handleChange(option.requestId)}
                >
                    <Checkbox
                        sx={{ p: 0.5, m: 0 }}
                        checked={selectedRequests.indexOf(option.requestId) > -1}
                        onClick={() => handleChange(option.requestId)}

                    />
                    <ListItemText primary={
                        option.requestName
                    } />
                </ListItem>
            ))}
        </Select>
        <FormHelperText error={selectedRequests.length === 0}>{selectedRequests.length === 0 ? "Select at least one batch" : `Viewing ${selectedRequests.length} of ${requestsWithData.length} batches`}</FormHelperText>
    </FormControl>

}

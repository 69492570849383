import { Auth0Provider } from "@auth0/auth0-react";
import App from "../App";
import { SubscriptionStateProvider } from "../subscription/SubscriptionStateContext";
import { CognitoAuthProvider } from './CognitoAuthContext';

export const AuthProviderWrapper = () => {
    const redirectUrl = window.location.origin
    if (process.env.REACT_APP_USE_COGNITO === 'TRUE') {
        return (
            // Check this cognito wrapper for more process.env variables specific to cognito
            <CognitoAuthProvider>
                <SubscriptionStateProvider>
                    <App />
                </SubscriptionStateProvider>
            </CognitoAuthProvider>
        );
    } else {
        const domain = process.env.REACT_APP_AUTH_DOMAIN as string
        const clientId = process.env.REACT_APP_AUTH_CLIENT_ID as string

        return <Auth0Provider
            domain={domain}
            clientId={clientId}
            authorizationParams={{ redirect_uri: redirectUrl }}
        >
            <SubscriptionStateProvider>
                <App />
            </SubscriptionStateProvider>
        </Auth0Provider>
    }
};
